<template>
  <div class="company-page">
    <h1>{{ isEdit ? 'Edit company' : 'Add company' }}</h1>

    <form class="card card-body bg-light" @submit.prevent="isEdit ? doCompanyAdminEdit() : doCompanyAdminAdd()">

      <div class="col-12 form-group">
        <label for="title" class="form-label">Title<span class="f-required">*</span></label>
        <input
            v-model="title"
            :class="{'is-invalid': errors?.title}"
            type="text"
            class="form-control"
            id="title" />
        <div class="invalid-feedback">{{ errors?.title }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="title" class="form-label">Payment token<span class="f-required">*</span></label>
        <input
            v-model="extPayToken"
            :class="{'is-invalid': errors?.extPayToken}"
            type="text"
            class="form-control"
            id="extPayToken" />
        <div class="invalid-feedback">{{ errors?.extPayToken }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <button class="btn btn-primary me-2" type="submit">Save</button>
        <router-link to="/company/admin" class="btn btn-outline-secondary">Cancel</router-link>
      </div>

    </form>

  </div>
</template>

<script>

export default {

  data: () => ({
    isEdit: false,

    title: null,
    extPayToken: null
  }),

  async mounted() {

    this.isEdit = this.$route.params?.id ? true : false;

    if (!await this.checkAdmin())
      return;

    this.setBreadcrumbs();

    if (this.isEdit)
      await this.doCompanyAdminEditInit();
  },

  methods: {

    async doCompanyAdminEditInit() {

      let apiHolder = await this.sendApiGet('api/company/admin/edit/init/' + this.$route.params?.id);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.companyData) {

        this.title = apiHolder.data.companyData?.title;
        this.extPayToken = apiHolder.data.companyData?.extPayToken;
      }
    },

    async doCompanyAdminEdit() {

      let apiHolder = await this.sendApiPost('api/company/admin/edit/' + this.$route.params?.id, {
        title: this.title,
        extPayToken: this.extPayToken
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/company/admin');
    },

    async doCompanyAdminAdd() {

      let apiHolder = await this.sendApiPost('api/company/admin/add', {
        title: this.title,
        extPayToken: this.extPayToken
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/company/admin');
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        ['Company management', '/company/admin'],
        this.isEdit ? 'Edit company' : 'Add company'
      ]);
    }

  },

}
</script>

<style scoped>

</style>